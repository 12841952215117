.header {
  background: url('../img/header-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--light-color);
  padding-top: 51px;
  padding-bottom: 201px;
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__company {
    margin: 0;
    max-width: 175px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    margin-right: 42px;
    @include tablet {
      display: none;
      color: var(--dark-color);
      text-align: center;
      margin: 0 auto;
      
    }
  }

  &__company  span{
    font-size: 15px;
    @include tablet {
      display: none;
      
    }
  }

  &__title {
    margin: 0;
    font-size: 67px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 256px;
    margin-top: 124px;
    @include mobile {
      font-size: 32px;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  &__btn {
    margin: 0 auto;
  }

}

.social {
  @include tablet {
    display: none;
    position: relative;
    align-items: center;
    justify-content: center;
  }
  position: absolute;
  top: 0;
  right: 0;
  max-width: 330px;
  display: flex;
  align-items: center;

  &__item {
    transition: all 0.5s ease-in-out;
    &:not(:last-child) {
      margin-right: 30px;
    }
    &:hover {
      opacity: .7;
    }
  }
}

.town {
  margin: 0;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 12px;
  @include tablet {
    display: none;
    margin: 0 auto;
    
  }
}

.phone {
  font-size: 16.9px;
  line-height: 15px;
  color: var(--light-color);
  display: flex;
  align-items: center;
  @include tablet {
    color: var(--dark-color);
    justify-content: center;
    display: none;
  }

  &:before {
    content: '';
    display: block;
    background: url('../img/phone.png');
    width: 19px;
    height: 19px;
    margin-right: 12px;
  }
}
.nav {

  @include tablet {
    display: none;
  }
  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    border-bottom: 3px solid rgba(0,0,0,0);
    padding-bottom: 5px;
    transition: all 0.5s ease-in-out;

    &:not(:last-child) {
      margin-right: 25px;
    }

    &:hover {
      border-bottom: 3px solid #ff4239;
    }
  }

  &__link {
    font-size: 20px;
    line-height: 18px;
    color: var(--light-color);
  }

}

.page-link {
  display: flex;
  align-items: center;
  &::after {
    content: '˅';
    display: block;
    margin-left: 5px;
    font-size: 16px;
  }
}

.shop-header {
  padding-top: 51px;
  padding-bottom: 68px;

  background: url('../img/shop_header_bg.png');

  background-color: var(--light-brown-color);
  
}



.place-wrapper {
  @include big-desktop {
    display: none;
  }
  @include tablet {
    color: var(--dark-color);
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

}


//burger

/* скрываем чекбокс */
#menu__toggle {
  opacity: 0;
}
/* стилизуем кнопку */
.menu__btn {
  display: flex; /* используем flex для центрирования содержимого */
  align-items: center;  /* центрируем содержимое кнопки */
  position: absolute;
  top: 83px;
  right: 20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 12;
  display: none;
  
  @include tablet {
    display: block;
  }
}
/* добавляем "гамбургер" */
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--acent-color);
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}

/* контейнер меню */
.menu__box {
  display: block;
  position: fixed;
  visibility: hidden;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 10;
  padding: 80px 0;
  list-style: none;
  text-align: center;
  background-color: #ECEFF1;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
}
/* элементы меню */
.menu__item {
  display: block;
  padding: 12px 24px;
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}
.menu__item:hover {
  background-color: #CFD8DC;
}

#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
}
#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  visibility: visible;
  left: 0;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  transition-duration: .25s;
}
.menu__box {
  transition-duration: .25s;
}
.menu__item {
  transition-duration: .25s;
}

.burger-visible {
  display: flex;
  margin-top: 20px;
}