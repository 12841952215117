.products {
  padding: var(--section-offset) 0;

  &__title {
    margin-bottom: 88px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 40px;
    @include tablet {
      grid-template-columns: repeat(2,1fr);
    }
    @include mobile {
      grid-template-columns: repeat(1,1fr);
    }
  }

  &__item {
    min-height: 309px;
    position: relative;
    
  }

  &__subtitile {
    position: absolute;
    bottom: 30px;
    left: 30px;
    margin: 0;
    color: #fff;
  }
}

