.aplic {
  padding: var(--section-offset) 0;
  background: linear-gradient( rgba(0, 0, 0, 0.67), rgba(0, 0, 0, 0.67)), url('../img/aplic_bg.jpg');
  &__title {
    color: #fff;
    margin-bottom: 50px;
  }

  &__desc {
    color: #fff;
    font-size: 22px;
    line-height: 38px;
    margin-bottom: 90px;
    text-align: center;
    @include mobile {
      font-size: 16px;
      margin-bottom: 50px;
    }
  }

  &__link {
    width: 127px;
  }

}
