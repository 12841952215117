.benefits {
  background: url('../img/benefits.png');
  padding: var(--section-offset) 0;
  padding-bottom: 100px;
  background-color: #d4cbc1;
  @include tablet {
    padding-bottom: 73px;
  }
  @include mobile {
    background: #fff;
  }

  &__title {
    margin-bottom: 112px;
    @include mobile {
      margin-bottom: 20px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile {
      flex-wrap: wrap;
    }
  }

  &__item {
    width: 16%;
    @include mobile {
      width: 100%;
    }
  }

  &__digit {
    margin: 0;
    font-family: 'Astakhov';
    font-weight: 400;
    color: var(--acent-color);
    font-size: 60px;
    margin-bottom: 31px;
    text-align: center;
  }

  &__desc {
    margin: 0;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    text-align: center;
  }
}

