html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 15px;
  font-weight: 400;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.btn {
  display: block;
  width: 328px;
  font-size: 25px;
  font-weight: 500;
  color: var(--light-color);
  background-color: var(--acent-color);
  padding: 19px 51px;
  box-shadow: 0px 6px 23px 6px rgba(0,0,0,0.36);
  border-radius: 31px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  &:hover {
    box-shadow: 0px 10px 30px 10px rgba(0,0,0,0.36);
  }
}

.section-title {
  margin: 0;
  font-size: 40px;
  font-weight: 500;
  color: var(--dark-color);
  text-align: center;

  @include mobile {
    font-size: 22px;
  }
}

.more {
  font-size: 20px;
  color: #fff;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin: 0 auto;
  display: block;

  &::after {
    content: '';
    display: block;
    border-bottom: 1px solid currentColor;
    margin-top: 11px;
    width: 100%;
    height: 2px;
  }
}





