.contacts {
  padding: var(--section-offset) 0;
  padding-bottom: 73px;
  background: url('../img/buy-bg.png');
  margin-top: -37px;

  &__title {
    margin-bottom: 68px;
  }

  &__desc {
    margin: 0 auto;
    font-size: 22px;
    line-height: 38px;
    max-width: 530px;
    text-align: center;
    margin-bottom: 68px;
    @include mobile {
      font-size: 16px;
      margin-bottom: 50px;
    }
  }

  &__link {
    width: 215px;
  }
}
