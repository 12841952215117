@charset "UTF-8";
:root {
  --font-family: "Ubuntu", sans-serif;
  --content-width: 1340px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fefefe;
  --dark-color: #222224;
  --acent-color: #ff6000;
  --light-brown-color: #f6f1ed;
  --section-offset: 120px;
}

@font-face {
  font-family: "Astakhov";
  src: url("../fonts/../fonts/Astakhov.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 15px;
  font-weight: 400;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.btn {
  display: block;
  width: 328px;
  font-size: 25px;
  font-weight: 500;
  color: var(--light-color);
  background-color: var(--acent-color);
  padding: 19px 51px;
  box-shadow: 0px 6px 23px 6px rgba(0, 0, 0, 0.36);
  border-radius: 31px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.btn:hover {
  box-shadow: 0px 10px 30px 10px rgba(0, 0, 0, 0.36);
}

.section-title {
  margin: 0;
  font-size: 40px;
  font-weight: 500;
  color: var(--dark-color);
  text-align: center;
}
@media (max-width: 576px) {
  .section-title {
    font-size: 22px;
  }
}

.more {
  font-size: 20px;
  color: #fff;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin: 0 auto;
  display: block;
}
.more::after {
  content: "";
  display: block;
  border-bottom: 1px solid currentColor;
  margin-top: 11px;
  width: 100%;
  height: 2px;
}

.header {
  background: url("../img/header-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--light-color);
  padding-top: 51px;
  padding-bottom: 201px;
}
.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.header__left {
  display: flex;
  align-items: center;
}
.header__company {
  margin: 0;
  max-width: 175px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  margin-right: 42px;
}
@media (max-width: 1024px) {
  .header__company {
    display: none;
    color: var(--dark-color);
    text-align: center;
    margin: 0 auto;
  }
}
.header__company span {
  font-size: 15px;
}
@media (max-width: 1024px) {
  .header__company span {
    display: none;
  }
}
.header__title {
  margin: 0;
  font-size: 67px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 256px;
  margin-top: 124px;
}
@media (max-width: 576px) {
  .header__title {
    font-size: 32px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
.header__btn {
  margin: 0 auto;
}

.social {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 330px;
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .social {
    display: none;
    position: relative;
    align-items: center;
    justify-content: center;
  }
}
.social__item {
  transition: all 0.5s ease-in-out;
}
.social__item:not(:last-child) {
  margin-right: 30px;
}
.social__item:hover {
  opacity: 0.7;
}

.town {
  margin: 0;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 12px;
}
@media (max-width: 1024px) {
  .town {
    display: none;
    margin: 0 auto;
  }
}

.phone {
  font-size: 16.9px;
  line-height: 15px;
  color: var(--light-color);
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .phone {
    color: var(--dark-color);
    justify-content: center;
    display: none;
  }
}
.phone:before {
  content: "";
  display: block;
  background: url("../img/phone.png");
  width: 19px;
  height: 19px;
  margin-right: 12px;
}

@media (max-width: 1024px) {
  .nav {
    display: none;
  }
}
.nav__list {
  display: flex;
  align-items: center;
}
.nav__item {
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  padding-bottom: 5px;
  transition: all 0.5s ease-in-out;
}
.nav__item:not(:last-child) {
  margin-right: 25px;
}
.nav__item:hover {
  border-bottom: 3px solid #ff4239;
}
.nav__link {
  font-size: 20px;
  line-height: 18px;
  color: var(--light-color);
}

.page-link {
  display: flex;
  align-items: center;
}
.page-link::after {
  content: "˅";
  display: block;
  margin-left: 5px;
  font-size: 16px;
}

.shop-header {
  padding-top: 51px;
  padding-bottom: 68px;
  background: url("../img/shop_header_bg.png");
  background-color: var(--light-brown-color);
}

@media (max-width: 1440px) {
  .place-wrapper {
    display: none;
  }
}
@media (max-width: 1024px) {
  .place-wrapper {
    color: var(--dark-color);
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/* скрываем чекбокс */
#menu__toggle {
  opacity: 0;
}

/* стилизуем кнопку */
.menu__btn {
  display: flex;
  /* используем flex для центрирования содержимого */
  align-items: center;
  /* центрируем содержимое кнопки */
  position: absolute;
  top: 83px;
  right: 20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 12;
  display: none;
}
@media (max-width: 1024px) {
  .menu__btn {
    display: block;
  }
}

/* добавляем "гамбургер" */
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--acent-color);
}

.menu__btn > span::before {
  content: "";
  top: -8px;
}

.menu__btn > span::after {
  content: "";
  top: 8px;
}

/* контейнер меню */
.menu__box {
  display: block;
  position: fixed;
  visibility: hidden;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 10;
  padding: 80px 0;
  list-style: none;
  text-align: center;
  background-color: #ECEFF1;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);
}

/* элементы меню */
.menu__item {
  display: block;
  padding: 12px 24px;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}

.menu__item:hover {
  background-color: #CFD8DC;
}

#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
}

#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
}

#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}

#menu__toggle:checked ~ .menu__box {
  visibility: visible;
  left: 0;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  transition-duration: 0.25s;
}

.menu__box {
  transition-duration: 0.25s;
}

.menu__item {
  transition-duration: 0.25s;
}

.burger-visible {
  display: flex;
  margin-top: 20px;
}

.products {
  padding: var(--section-offset) 0;
}
.products__title {
  margin-bottom: 88px;
}
.products__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}
@media (max-width: 1024px) {
  .products__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 576px) {
  .products__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.products__item {
  min-height: 309px;
  position: relative;
}
.products__subtitile {
  position: absolute;
  bottom: 30px;
  left: 30px;
  margin: 0;
  color: #fff;
}

.why-me {
  padding: var(--section-offset) 0;
  background-color: var(--light-brown-color);
}
.why-me__title {
  margin-bottom: 90px;
}
.why-me__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .why-me__wrapper {
    flex-wrap: wrap;
  }
}
.why-me__item {
  width: 21%;
  text-align: center;
}
@media (max-width: 576px) {
  .why-me__item {
    width: 100%;
  }
}
.why-me__img {
  margin-bottom: 27px;
}
.why-me__subtitle {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 27px;
  text-align: center;
}
.why-me__desc {
  text-align: center;
}

.contacts {
  padding: var(--section-offset) 0;
  padding-bottom: 73px;
  background: url("../img/buy-bg.png");
  margin-top: -37px;
}
.contacts__title {
  margin-bottom: 68px;
}
.contacts__desc {
  margin: 0 auto;
  font-size: 22px;
  line-height: 38px;
  max-width: 530px;
  text-align: center;
  margin-bottom: 68px;
}
@media (max-width: 576px) {
  .contacts__desc {
    font-size: 16px;
    margin-bottom: 50px;
  }
}
.contacts__link {
  width: 215px;
}

.aplic {
  padding: var(--section-offset) 0;
  background: linear-gradient(rgba(0, 0, 0, 0.67), rgba(0, 0, 0, 0.67)), url("../img/aplic_bg.jpg");
}
.aplic__title {
  color: #fff;
  margin-bottom: 50px;
}
.aplic__desc {
  color: #fff;
  font-size: 22px;
  line-height: 38px;
  margin-bottom: 90px;
  text-align: center;
}
@media (max-width: 576px) {
  .aplic__desc {
    font-size: 16px;
    margin-bottom: 50px;
  }
}
.aplic__link {
  width: 127px;
}

.benefits {
  background: url("../img/benefits.png");
  padding: var(--section-offset) 0;
  padding-bottom: 100px;
  background-color: #d4cbc1;
}
@media (max-width: 1024px) {
  .benefits {
    padding-bottom: 73px;
  }
}
@media (max-width: 576px) {
  .benefits {
    background: #fff;
  }
}
.benefits__title {
  margin-bottom: 112px;
}
@media (max-width: 576px) {
  .benefits__title {
    margin-bottom: 20px;
  }
}
.benefits__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .benefits__wrapper {
    flex-wrap: wrap;
  }
}
.benefits__item {
  width: 16%;
}
@media (max-width: 576px) {
  .benefits__item {
    width: 100%;
  }
}
.benefits__digit {
  margin: 0;
  font-family: "Astakhov";
  font-weight: 400;
  color: var(--acent-color);
  font-size: 60px;
  margin-bottom: 31px;
  text-align: center;
}
.benefits__desc {
  margin: 0;
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
}

.rewievs {
  background-color: #d4cbc1;
  padding: var(--section-offset) 0;
}
.rewievs__title {
  margin-bottom: 89px;
}
@media (max-width: 576px) {
  .rewievs__title {
    margin-bottom: 20px;
  }
}
.rewievs__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 87px;
}
.rewievs__item {
  width: 100%;
  background-color: #fff;
  border: 2px solid #d4cbc1;
  border-radius: 66.5px;
  padding: 35px 101px 27px 73px;
}
.rewievs__item:not(:last-child) {
  margin-bottom: 11px;
}
@media (max-width: 576px) {
  .rewievs__item {
    padding: 20px;
  }
}
.rewievs__name {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 19px;
}
@media (max-width: 576px) {
  .rewievs__name {
    font-size: 18px;
  }
}
.rewievs__offer {
  margin: 0;
  line-height: 22px;
}
@media (max-width: 576px) {
  .rewievs__offer {
    font-size: 14px;
  }
}
.rewievs__more {
  color: var(--dark-color);
  width: 142px;
}

.partnets {
  padding-top: 80px;
}

.carousel {
  margin-top: 50px;
}
.carousel__wrapper {
  display: flex;
  align-items: center;
}
.carousel__slide {
  min-height: 300px;
}
.carousel__img {
  object-fit: cover;
}

.footer {
  background-color: #3b3c40;
  padding-top: 51px;
  padding-bottom: 78px;
}
.footer__container {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
@media (max-width: 576px) {
  .footer__container {
    flex-direction: column;
  }
}
.footer__left {
  display: flex;
  align-items: center;
}
@media (max-width: 576px) {
  .footer__left {
    flex-direction: column;
    align-items: flex-start;
  }
}
.footer__company {
  margin: 0;
  color: var(--light-color);
  margin-left: 76px;
  margin-right: 154px;
  margin-bottom: auto;
  display: block;
}
@media (max-width: 576px) {
  .footer__company {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
.footer__list {
  color: var(--dark-color);
  flex-direction: column;
}
.footer__list .nav__link {
  color: var(--dark-color);
}
.footer__right {
  color: var(--light-color);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer__phone {
  color: var(--light-color);
}

.footer-menu__item {
  transition: all 0.5s ease-in-out;
}
.footer-menu__item:hover .fotter__link {
  opacity: 0.9;
}
.footer-menu__item:not(:last-child) {
  margin-bottom: 10px;
}
.footer-menu__link {
  margin: 0;
  font-size: 17px;
  color: #fefefe;
  font-weight: 400;
}

.copyright {
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  color: #959494;
  text-align: right;
  margin-top: 91px;
}

.sidebar {
  width: 250px;
  margin-right: 50px;
}
.sidebar__title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--acent-color);
  margin-bottom: 12px;
}
.sidebar__item {
  cursor: pointer;
  border: 2px solid var(--light-brown-color);
  transition: all 0.5s ease-in-out;
  border-radius: 18px;
  padding: 10px 17px;
}
.sidebar__item:not(:last-child) {
  margin-bottom: 8px;
}
.sidebar__item:hover {
  border: 2px solid var(--acent-color);
}
.sidebar__item--active {
  border: 2px solid var(--acent-color);
}
.sidebar__link {
  font-size: 14px;
  font-weight: 400;
  color: #111212;
}

.shop {
  padding: var(--section-offset) 0;
  background-color: var(--light-brown-color);
}
.shop__container {
  margin-top: 67px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .shop__container {
    flex-direction: column;
  }
}

.catalog {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
}
@media (max-width: 1440px) {
  .catalog {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1024px) {
  .catalog {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .catalog {
    grid-template-columns: repeat(1, 1fr);
  }
}
.catalog__item {
  background-color: #fff;
  position: relative;
}
.catalog__info p {
  margin: 0;
}
.catalog__img {
  width: 100%;
  height: 247px;
}
.catalog__wrapper {
  padding: 10px;
}
.catalog__title {
  margin: 0;
  margin-bottom: 10px;
}
.catalog__list {
  border-bottom: 2px solid #ede7e0;
  padding-bottom: 35px;
}
.catalog__info {
  font-size: 13px;
  font-weight: 400;
}
.catalog__info:not(:last-child) {
  margin-bottom: 5px;
}

.price {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}
.price__all {
  margin: 0;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  color: #1d1d1b;
  margin-right: 47px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.price__all::after {
  content: "В розницу";
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
  color: currentColor;
}
.price__opt {
  margin: 0;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  color: var(--acent-color);
  display: flex;
  flex-direction: column;
  text-align: center;
}
.price__opt::after {
  content: "Оптом";
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
  color: currentColor;
}

.new {
  position: absolute;
  top: 21px;
  left: 0;
  background-color: var(--acent-color);
  padding: 6px 9px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.new img {
  width: 23px;
  height: 23px;
}