.shop {
  padding: var(--section-offset) 0;
  background-color: var(--light-brown-color);

  &__container {
    margin-top: 67px;
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
    }
  }
}

.catalog {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 50px;
  @include big-desktop {
    grid-template-columns: repeat(3,1fr);
  }
  @include tablet {
    grid-template-columns: repeat(2,1fr);
  }
  @include small-tablet {
    grid-template-columns: repeat(1,1fr);
  }

  &__item {
    background-color: #fff;
    position: relative;

  }

  &__info p {
    margin: 0;
  }

  &__img {
    width: 100%;
    height: 247px;
  }

  &__wrapper {
    padding: 10px;
  }

  &__title {
    margin: 0;
    margin-bottom: 10px;
  }

  &__list {
    border-bottom: 2px solid #ede7e0;
    padding-bottom: 35px;
  }

  &__info {
    font-size: 13px;
    font-weight: 400;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
.price {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;

  &__all {
    margin: 0;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    color: #1d1d1b;
    margin-right: 47px;
    display: flex;
    flex-direction: column;
    text-align: center;

    &::after {
      content:'В розницу';
      font-size: 13px;
      line-height: 19px;
      font-weight: 400;
      color: currentColor;
    }
  }

  &__opt {
    margin: 0;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    color: var(--acent-color);
    display: flex;
    flex-direction: column;
    text-align: center;

    &::after {
      
      content:'Оптом';
      font-size: 13px;
      line-height: 19px;
      font-weight: 400;
      color: currentColor;
    }
  }
}

.new {
  position: absolute;
  top: 21px;
  left: 0;
  background-color: var(--acent-color);
  padding: 6px 9px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.new img {
  width: 23px;
  height: 23px;
}


