.partnets {
  padding-top: 80px;
}

.carousel {
  margin-top: 50px;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__slide {
    min-height: 300px;
  }

  &__img {
    object-fit: cover;
  }
}