:root {
  // base
  --font-family: "Ubuntu", sans-serif;
  --content-width: 1340px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fefefe;
  --dark-color: #222224;
  --acent-color: #ff6000;
  --light-brown-color: #f6f1ed;

  //offset
  --section-offset: 120px;

}
