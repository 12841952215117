.footer {
  background-color: #3b3c40;
  padding-top: 51px;
  padding-bottom: 78px;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: start;
    @include mobile {
      flex-direction: column;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__company {
    margin: 0;
    color: var(--light-color);
    margin-left: 76px;
    margin-right: 154px;
    margin-bottom: auto;
    display: block;
    @include mobile {
     margin-left: 0;
     margin-right: 0;
     margin-bottom: 10px;
     margin-top: 10px;
    }
  }

  &__list {
    color: var(--dark-color);
    flex-direction: column;
  }

  &__list .nav__link {
    color: var(--dark-color);
  }

  &__right {
    color: var(--light-color);
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__phone {
    color: var(--light-color);
  }
}

.footer-menu {

  &__item {
    transition: all 0.5s ease-in-out;
    &:hover .fotter__link {
      opacity: 0.9;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__link {
    margin: 0;
    font-size: 17px;
    color: #fefefe;
    font-weight: 400;
  }
}

.copyright {
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  color: #959494;
  text-align: right;
  margin-top: 91px;
}

