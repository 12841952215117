.why-me {
  padding: var(--section-offset) 0;
  background-color: var(--light-brown-color);

  &__title {
    margin-bottom: 90px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      flex-wrap: wrap;
    }

  }

  &__item {
    width: 21%;
    text-align: center;
    @include mobile {
      width: 100%;
    }
  }

  &__img {
    margin-bottom: 27px;
  }

  &__subtitle {
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 27px;
    text-align: center;
  }

  &__desc {
    text-align: center;
  }

}
