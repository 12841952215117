.sidebar {
  width: 250px;
  margin-right: 50px;

  &__title {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: var(--acent-color);
    margin-bottom: 12px;

  }

  &__item {
    cursor: pointer;
    border: 2px solid var(--light-brown-color);;
    transition: all 0.5s ease-in-out;
    border-radius: 18px;
    padding: 10px 17px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:hover {
      border: 2px solid var(--acent-color);
    }
    &--active {
      border: 2px solid var(--acent-color);
    }

  }

  &__link {
    font-size: 14px;
    font-weight: 400;
    color: #111212;
  }
}
