.rewievs {
  background-color: #d4cbc1;
  padding: var(--section-offset) 0;

  &__title {
    margin-bottom: 89px;
    @include mobile {
      margin-bottom: 20px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 87px;
  }

  &__item {
    width: 100%;
    background-color: #fff;
    border: 2px solid #d4cbc1;
    border-radius: 66.5px;
    padding: 35px 101px 27px 73px;



    &:not(:last-child) {
      margin-bottom: 11px;
    }

    @include mobile {
      padding: 20px;
    }
  }

  &__name {
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 19px;
    @include mobile {
      font-size: 18px;
    }
  }

  &__offer {
    margin: 0;
    line-height: 22px;
    @include mobile {
      font-size: 14px;
    }
  }

  &__more {
    color: var(--dark-color);
    width: 142px;
  }
}


